const typeTable: Record<string, string> = {
    twitter: '/assets/images/icon_X_square.png',
    facebook: '/assets/images/f_logo_RGB-White_58.png',
    roundedTwitter: '/assets/images/icon_X_black.png ',
    roundedFacebook: '/assets/images/icon@2x_Facebook.png',
    roundedTwitterPrimary: '/assets/images/icon_X.png',
    roundedFacebookPrimary: '/assets/images/FacebookRounded.png'
};

function TwitterLogo() {
    return (
        <div className="flex justify-center items-center bg-[#000000] rounded w-[100%] h-[100%]">
            <img
                src={typeTable['twitter']}
                alt=""
                className="w-[66%] h-[66%]"
            />
        </div>
    );
}

function RoundedTwitterLogo() {
    return (
        <div className="flex justify-center items-center w-[100%] h-[100%]">
            <img
                src={typeTable['twitter']}
                alt=""
                className="w-[86%] h-[86%]"
            />
        </div>
    );
}

function RoundedTwitterLogoPrimary() {
    return (
        <div className="flex justify-center items-center w-[100%] h-[100%]">
            <img
                src={typeTable['roundedTwitterPrimary']}
                alt=""
                className="w-[85%] h-[85%]"
            />
        </div>
    );
}

function FacebookLogo() {
    return (
        <div className="flex justify-center items-center bg-[#3975EA] rounded w-[100%] h-[100%]">
            <img
                src={typeTable['facebook']}
                alt=""
                className="w-[55%] h-[55%]"
            />
        </div>
    );
}

function RoundedFacebookLogo() {
    return (
        <div className="flex justify-center items-center  w-[100%] h-[100%]">
            <img
                src={typeTable['roundedFacebook']}
                alt=""
                className="w-[100%] h-[100%]"
            />
        </div>
    );
}

function RoundedFacebookLogoPrimary() {
    return (
        <div className="flex justify-center items-center  w-[100%] h-[100%]">
            <img
                src={typeTable['roundedFacebookPrimary']}
                alt=""
                className="w-[100%] h-[100%]"
            />
        </div>
    );
}

export default function Logo({ type }: { type: string }) {
    switch (type) {
        case 'twitter':
            return <TwitterLogo />;
        case 'roundedTwitter':
            return <RoundedTwitterLogo />;
        case 'roundedTwitterPrimary':
            return <RoundedTwitterLogoPrimary />;
        case 'facebook':
            return <FacebookLogo />;
        case 'roundedFacebook':
            return <RoundedFacebookLogo />;
        case 'roundedFacebookPrimary':
            return <RoundedFacebookLogoPrimary />;
        default:
            return <></>;
    }
}
