import Logo from '../../../components/Logo';

export default function ShareButton({
    className,
    type,
    url,
    text
}: {
    className?: string;
    type: string;
    url: string;
    text?: string;
}) {
    const twitterUrl = `https://twitter.com/share?url=${url}&text=${
        text || ''
    }`;
    const facebookUrl = `https://www.facebook.com/share.php?u=${url}`;
    const shareUrls: {
        [key: string]: string;
    } = {
        twitter: twitterUrl,
        roundedTwitter: twitterUrl,
        roundedTwitterPrimary: twitterUrl,
        facebook: facebookUrl, // facebookはテキスト追加不可
        roundedFacebook: facebookUrl, // facebookはテキスト追加不可
        roundedFacebookPrimary: facebookUrl // facebookはテキスト追加不可
    };
    const validType = type in shareUrls;

    return validType ? (
        <div className={`${className}`}>
            <a href={shareUrls[type]} target="_blank" rel="noreferrer">
                <Logo type={type} />
            </a>
        </div>
    ) : (
        <></>
    );
}
